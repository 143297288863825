.carpark-deduction-rules {
   .member-level {
       display: flex;
       justify-content: space-between;
   } 

   .number-input {
       margin: 0 10px;
   }

   .carpark-deduction-rule {
       padding: 10px;
       background-color: #f8f8f8;
       margin-bottom: 20px;
   }

   .rule-values {
        display: flex;
   }

   .carpark-deduction-rules-tips {
       color: #b8b8b8;
   }
}