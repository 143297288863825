.live-room-product-add {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    gap: 15px;
    &__item {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        gap: 15px;
        width: 100%;
    }
    &__label {
        flex-shrink: 0;
        flex-grow: 0;
        white-space: nowrap;
        align-items: flex-start;
    }
    &__value {
        flex-shrink: 1;
        flex-grow: 1;
        width: 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
    }
    &__price {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        word-break: keep-all;
        width: 300px;
        & > * {
            flex-shrink: 1;
            flex-grow: 1;
        }
    }
}
