@import '~antd/es/style/themes/default.less';

.baseView {
    display: flex;
    padding-top: 12px;

    :global {
        .ant-legacy-form-item .ant-legacy-form-item-control-wrapper {
            width: 100%;
        }
        .ant-form-item{
            margin-bottom: 12px;
        }
    }

    .left {
        min-width: 224px;
        max-width: 448px;
        flex: 2;
    }

    .title {
        color: #000;
    }

    .value-text {
        // color: #888;
        font-size: 14px;
    }

    .right {
        flex: 1;
        padding-left: 104px;

        .avatar {
            width: 120px;
            height: 120px;
            margin-bottom: 12px;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        .button_view {
            width: 144px;
            text-align: center;
        }
    }
}
