.message-notification-item {
    width: 380px;
    padding: 10px 15px 20px 15px;
    border-radius: 10px;
    border: 1px solid #e3e3e3;
    margin-right: 20px;
    margin-bottom: 20px;
    line-height: 1.5;

    &__tip {
        color: #999;
        font-size: 12px;
        line-height: 24px;
    }

    &__content {
        margin-top: 12px;
        word-break: break-all;
        word-wrap: break-word;
        background: #f1f1f1;
        border-radius: 5px;

        &__title {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 8px;
        }
    }

    &__other-items {
        padding: 12px;
        overflow-y: scroll;
        height: 150px;
    }

    &__other-item {
        display: flex;
        flex-direction: row;
        word-break: break-all;
        overflow-wrap: break-word;

        &__title {
            color: #999;
            width: 90px;
            flex-shrink: 0;
        }

        &__value {
            flex: 1;
            color: #000;
            white-space: 'pre-line';
        }

        &__pic-value {
            display: flex;
            flex: 1;
            color: #000;
            white-space: 'pre-line';

            .img {
                width: 56px;
                height: 56px;
                margin: 5px 10px 0 0;
            }

            .value {
                flex: 1;
                
            }
        }
    }

    &__wechat {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        padding: 10px;
        border-top: 1px solid #e3e3e3;
    }

    &__email-items,
    &__mail-items{
        padding: 12px;
        overflow-y: scroll;
        height: 233px;
    }

    &__rich-text {
        width: 310px;
        .quill-editor{
            .ql-toolbar {
                display: none;
            }
            .ql-container {
                border: none;
            }
            .quill {
                height: auto !important;
            }
            .ql-editor {
                font-size: 14px;
                padding: 2px;
                color: #000;
            }
        }
    }
}