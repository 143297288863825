@import '~@comall-backend-builder/design-components-basis/dist/index.less';

.upload-picture-tips {
    color: #c6c6c6;
    margin-top: -20px;
}

// .span-link {
//     color: @primary-color;
// }
