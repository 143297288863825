.discount-limit {
    .discount-limit-content {
        display: flex;
        align-items: center;
    }

    .discount-limit-type {
        width: 140px;

        .ant-select-selection {
            background-color: #fafafa;
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .discount-limit-tips {
        color: #b8b8b8;
    }
}