.custom-crowd-form{
    .rule-main{
        display: flex;
        flex-direction: row;
        width:100%;
        .rule-list{
            height: 65vh;
            overflow: hidden;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 5px;
                margin-left: 10px;
            }
        
            &::-webkit-scrollbar-track {
                background-color: #999;
            }
        
            &::-webkit-scrollbar-corner {
                background-color: #666;
            }
        
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-clip: padding-box;
                background-color: #ccc;
            }
            .ant-list-empty-text{
                display: none;
            }
            .rule-li{
                background-color: #fafafa;
                padding:15px 10px;
                margin-bottom: 20px;
            }
            .pub-section{
                width: 100%;
                position: relative;
                .delete{
                    position: absolute;
                    right: -5px;
                    top: -12px;
                    font-size: 16px;
                }
                .row-main{
                    display: flex;
                    flex-direction: row;
                    .label{
                        flex-shrink: 0;
                    }
                }
                .ant-form-extra{
                    font-size: 13px;
                }
            }
        }
        .no-rule-main{
            height: 55vh;
            p{
                margin-bottom: 5px;
            }
            .no-rule-main-img-box{
                background-color: #fafafa;
            }
        }
    }
    .characteristic-main{
        width: 96%;
        .box{
            margin-top:24px;
            padding:20px;
            background-color: #fafafa;
            .item-main{
                display: flex;
                flex-direction: row;
            }
            .title{
                flex-shrink: 0;
            }
            .items-main{
                margin:20px 0px 0px 0px;
            }
            .options-main{
                display: flex;
                flex-wrap: wrap;
            }
            .btn{
                border:1px solid #dbdbdb;
                width:100px;
                height: 30px;
                line-height: 30px;
                margin:0px 10px 10px 10px;
                text-align: center;
                &.selected-btn{
                    border: 1px solid #1890ff;
                }
            }
        }
    }
}