.pointer-events-none{
    pointer-events: none;
    .lens{
        position: fixed;
        z-index: 10000;
        opacity: 20;
        background: rgba(25,144,255,0.5);
    }
    .viewport{
        position: fixed;
        z-index: 10000;
        background: white;
        border-radius: 8px;
        border: 1px solid rgba(0,0,0,0.08);
        overflow: hidden;
    }
    .block{
        display: block;
    }
    .object-fill{
        object-fit: fill;
        // image-rendering: pixelated;
        image-rendering:-moz-crisp-edges;
        image-rendering:-o-crisp-edges;
        image-rendering:-webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode:nearest-neighbor;
    }
}