.message-notification-item-edit {
    &__button-wrap {
        display: flex;
        padding: 10px;
        justify-content: flex-end;
    }

    &__button {
        width: 62px;
        height: 25px;
        line-height: 23px;
        font-size: 12px;

        &.send {
            width: 88px;
        }
    }
}