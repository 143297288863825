.message-notification-weapp-edit-modal {
    display: flex;
    flex-direction: row;

    &__left {
        width: 375px;
        height: 667px;
        background: #F6F6F6;
        overflow-y: auto;

        &__info {
            &__top {
                position: relative;
                padding: 6px;
                width: 100%;
                background: #fff;

                &__name {
                    position: absolute;
                    top: 30px;
                    left: 0;
                    width: 375px;
                    color: #000;
                    font-size: 17px;
                    text-align: center;
                }
            }

            &__data {
                margin: 12px 16px;
                width: 343px;
                background: #FFFFFF;
                border-radius: 8px;

                &__content {
                    min-height: 300px;
                    padding: 20px;
                    font-size: 14px;
                    border-bottom: 1px solid #e3e3e3;

                    &__header {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        font-size: 16px;
                        color: #000;
                    }

                    &__dian-wrap {
                        display: flex;
                        flex-direction: row;
                    }

                    &__dian {
                        margin-left: 4px;
                        width: 4px;
                        height: 4px;
                        background: #999999;
                        border-radius: 50px;
                    }

                    &__center {
                        margin-top: 8px;
                    }

                    &__other {
                        margin-top: 24px;
                    }

                    &__other-item {
                        display: flex;
                        flex-direction: row;
                        word-break: break-all;
                        overflow-wrap: break-word;
                        margin-bottom: 8px;
                    }

                    &__bottom {
                        padding: 8px 0 32px 0;
                    }
                }

                &__footer {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 10px;
                }
            }
        }
    }

    &__right {
        width: 600px;
        &__title{
            margin: 0 30px 30px 30px;
            padding: 0 10px 10px 10px;
            font-size: 16px;
            border-bottom: 1px solid #e3e3e3;
        }
        &__content{
            margin: 0 30px 30px 30px;
            padding: 15px 30px;
            background: #f1f1f1;
            border-radius: 5px;
            .message-notification-weapp-edit-modal__flex {
                margin-bottom: 15px;
            }
            .message-notification-weapp-edit-modal__flex:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__parameter {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #b3b3b3;
        padding-left: 75px;
        margin-bottom: 20px;
    }

    &__flex{
        display: flex;
        margin-bottom: 30px;
    }
    &__title {
        width: 25%;
        text-align: right;
    }
    &__value {
        width: 70%;
        &.link-type {
            margin-left: calc(25% - 70px);
            .field-name {
                display: flex;
                align-items: center;
                color: rgba(0, 0, 0, 0.65);
            }
            // .link-item-field {
            //     &:nth-child(2) {
            //         display: none;
            //     }
            // }
        }
    }
    &__other-wrap{
        position: relative;
        &__icon{
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
    .link-item-field {
        align-items: center;
    }
}