.code-scanner-modal {
    .ant-modal {
        width: 416px !important;
    }
    .ant-modal-footer {
        padding-bottom: 24px;
        border-top: 0;
        text-align: center;
    }
    p {
        padding: 0 38px;
    }
}

.code-scan-panel {
    width: 400px;
    text-align: right;
    // position: absolute;
    // right: 260px;
    // top: 105px;
    margin-bottom: 12px;
}

@media (max-width: 767px) {
    .code-scanner-modal {
        .ant-modal {
            width: auto !important;
        }
    }
}
